import inflection from 'inflection'
import _ from 'lodash'
import { buildQuery } from './core'

export default (options) => {
  const { name, fetchAll = false} = options
  const nameToUse = name.replace('-', '_')
  const capitalized = inflection.capitalize(nameToUse)
  const prefix = inflection.camelize(nameToUse, true)
  const pluralized = inflection.pluralize(capitalized)
  
  const RECORDS = `${prefix}Records`
  const RECORDS_LOCAL = `${prefix}RecordsLocal`
  const HAS_MORE = `hasMore${pluralized}`
  const FILTERS = `${prefix}Filters`
  const SELECTS = `${prefix}Selects`
  const INCLUDES = `${prefix}Includes`
  const EXCLUDES = `${prefix}Excludes`
  const SORTS = `${prefix}Sorts`
  const MORE_DATA = `${prefix}MoreData`
  const MORE_OPTIONS = `${prefix}MoreOptions`
  const QUERY = `${prefix}Query`
  const CACHE_KEY = `${prefix}CacheKey`
  const CACHE_CLEAR = `${prefix}CacheClear`
  const LOAD_ACTION = `loadMore${pluralized}`
  const CLEAR_ACTION = `loadMore${pluralized}Reset`

  const mixin = {
    data() {
      return {
        [RECORDS_LOCAL]: [],
        [MORE_DATA]: {
          totalItemsInLocal: 0,
          totalItems: 0,
          startFrom: 0,
          loadStep: fetchAll ? -1 : 2,
          hasMoreData: true,
          withCount: false,
          isFirstQuery: true
        },
        [MORE_OPTIONS]: {
          cacheKeyPrefix: `${prefix}Cache`,
          cacheKeyList: [],
          cacheTTL: 3000, // in seconds
          cacheEnabled: false
        }
      }
    },

    computed: {
      [FILTERS]() {
        return [];
      },

      [SELECTS]() {
        return ['*'];
      },

      [INCLUDES]() {
        return ['*']
      },

      [EXCLUDES]() {
        return [];
      },
      [SORTS]() {
        return []
      },
      [QUERY]() {
        let filters = this[FILTERS];
        let selects = this[SELECTS];
        let excludes = this[EXCLUDES];
        let includes = this[INCLUDES]
        let sorts = this[SORTS]
        return buildQuery(name, filters, selects, includes, excludes, sorts);
      },
      [RECORDS]() {
        return this[RECORDS_LOCAL] || [];
      },
      [HAS_MORE]() {
        let more = this[MORE_DATA];
        return (more.hasMoreData || (more.totalItemsInLocal < more.totalItems))
      }
    },

    methods: {
      async [CACHE_CLEAR](key) {
        if (!key) {
          // clear all cache
          const keys = this[MORE_OPTIONS].cacheKeyPrefix
          await Promise.all( keys.map( async (key) => {
            await this.$api.parseCache.clearCache(key)
          }))
        } else {
          await this.$api.parseCache.clearCache(key)
        }
      },

      [CACHE_KEY](skipItems) {
        const key = `${this[MORE_OPTIONS].cacheKeyPrefix}-${skipItems}}`
        if (!_.find(this[MORE_OPTIONS].cacheKeyPrefix, (x) => x == key)) {
          this[MORE_OPTIONS].cacheKeyPrefix.push(key)
        }
        return key
      },

      async [`${LOAD_ACTION}DebouncedProxy`]() {
        if (options.debounce) {
          const cachedDebounceFunction = this[`${LOAD_ACTION}Debounced`]
          const mostRecentTime = this[`${LOAD_ACTION}MostRecentDebounceTime`]

          if (
            !cachedDebounceFunction ||
            mostRecentTime != options.debounce
          ) {
            this[`${LOAD_ACTION}MostRecentDebounceTime`] = options.debounce
            this[`${LOAD_ACTION}Debounced`] = _.debounce(
              this[LOAD_ACTION],
              options.debounce
            )
          }
          return await this[`${LOAD_ACTION}Debounced`]()
        } else {
          return await this[LOAD_ACTION]()
        }
      },

      async [LOAD_ACTION](force) {
        let more = this[MORE_DATA];
        if (!force && (
            !more.hasMoreData || 
            (more.totalItems > 0 && more.totalItemsInLocal > 0 && more.totalItemsInLocal >= more.totalItems)))
        {
          console.log('No more records to fetch');
          return;
        }

        if (force) {
          this[CLEAR_ACTION]()
        }

        let query = this[QUERY];
        query.skip(more.totalItemsInLocal);

        let loadStep = more.loadStep;
        if (loadStep > 0) {
          query.limit(loadStep);
        }

        let useCache = this[MORE_OPTIONS].cacheEnabled
        let cacheUsed = false
        if (more.isFirstQuery) {
          if (useCache) {
            query.cache(this[MORE_OPTIONS].cacheTTL, this[CACHE_KEY](more.totalItemsInLocal))
            cacheUsed = true
          }
          if (more.withCount) {
            query.withCount();
          }
          more.isFirstQuery = false;
        }

        if (!cacheUsed && useCache) {
          query.cache(this[MORE_OPTIONS].cacheTTL, this[CACHE_KEY](more.totalItemsInLocal))
        }

        let res = await query.find();
        let objects = res
        // first query, count may be returned, and should be
        // set to 'totalItems' field
        if (!_.isNil(res.results)) {
          objects = res.results
          more.totalItems = res.count
        }

        if (objects && objects.length > 0) {
          objects.forEach(obj => {
            this[RECORDS_LOCAL].push(obj)
          })
          more.totalItemsInLocal = more.totalItemsInLocal + objects.length;
        }
        if (objects && objects.length < more.loadStep) {
          more.hasMoreData = false
        }
      },

      [CLEAR_ACTION]() {
        this[RECORDS_LOCAL].splice(0, this[RECORDS_LOCAL].length)
        this[MORE_DATA].totalItemsInLocal = 0
        this[MORE_DATA].totalItems = 0
        this[MORE_DATA].startFrom = 0
        this[MORE_DATA].isFirstQuery = true
        this[MORE_DATA].hasMoreData = true
      }
    },

    async created() {
      let watch = options.watch ? options.watch : [QUERY /*, FILTERS, SELECTS, EXCLUDES */];
      watch.forEach(prop => {
        if (typeof prop !== 'string') {
          throw new Error(`Values in the 'watch' array must be strings.`)
        }
        this.$watch(prop, async function() {
          this[CLEAR_ACTION]()
          return await this[`${LOAD_ACTION}DebouncedProxy`]()
        })
      })

      return await this[LOAD_ACTION]()
    }
  }

  return mixin;
}